<template>
  <div>
    <div class="pf-list page-box">
      <div class="pf-item head">
        <div class="idx">
          <div class="num-index">序号</div>
        </div>
        <div class="item">
          <div class="h-item">
            <div class="headimg"></div>
            <div class="title">标题</div>
            <div class="pmid">PMID</div>
            <div class="status">状态</div>
            <div class="time">求助时间</div>
            <div class="action">操作</div>
          </div>
        </div>
      </div>
      <div class="pf-item" v-for="(item, index) in fulltextList" :key="item.id">
        <div class="idx">
          <div class="num-index">{{ index+1 }}</div>
        </div>
        <div class="item">
          <help-item :hitem="item"
            :unionid="authUnionid"
            @on-item-delete="onItemDelete"
            @on-item-renew="onItemReNew"
            @on-help-confirm="onHelpConfirm"></help-item>
        </div>
      </div>
    </div>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :tipType="tipType" :duration="duration" :text="tipText"></tooltip-win>
  </div>
</template>
<script>
import HelpItem from './help-item'
import TooltipWin from '../login-tip-win/tooltip-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {HelpItem, TooltipWin},
  data () {
    return {
      fulltextList: [],
      authUnionid: '',

      showTipWin: false,
      tipType: '',
      duration: '',
      tipText: ''
    }
  },
  mounted () {
    document.title = '我的求助'
    this.getAuthUnionid()
    this.loadPFList()
  },
  methods: {
    loadPFList () {
      this.$http.get(`${this.httpRoot}/paper/fulltext/list/me`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.fulltextList = res.fulltextList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    getAuthUnionid () {
      this.$http.get(`${this.httpRoot}/auth/unionid`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.authUnionid = res.unionid || 'me'
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onItemDelete () {
      this.loadPFList()
    },
    onItemReNew () {
      this.loadPFList()
    },
    onHelpConfirm () {
      this.loadPFList()
    },
    showCopyTitleSuccess () {
      this.tipType = 'success'
      this.duration = 1000
      this.tipText = '标题复制成功！'
      this.showTipWin = true
    },
    showCopyTitleLinkSuccess () {
      this.tipType = 'success'
      this.duration = 1000
      this.tipText = '标题和链接复制成功！'
      this.showTipWin = true
    }
  }
}
</script>
<style lang="less">
.pf-list {
  position: relative;
}
.pf-item {
  position: relative;
  padding: 5px 0;
  .idx {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 8px;
    font-size: 13px;
  }
  .num-index {
    padding-left: 5px;
  }
  .item {
    margin-left: 2.5rem;
  }
  &.head {
    background-color: #e9eaef;
    border-bottom: 1px solid #ddd;
  }
}
.pf-list .pf-item:nth-child(2n) {
  background: #fafafa;
}
</style>
